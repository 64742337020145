import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpStatusCode } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastService } from '@design/toast/services/toast.service';
import { showToast } from '@core/errors/models/context';

@Injectable()
export class ErrorsInterceptor implements HttpInterceptor {
  constructor(private toastService: ToastService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((errorResponse: HttpErrorResponse) => {
        if (errorResponse.error instanceof ProgressEvent && navigator.onLine) {
          this.toastService.showWarningToast('general-errors.server-not-respond');
        }

        if (this.isServerError(errorResponse)) {
          if (request.context.get(showToast)) {
            this.toastService.showErrorToast('general-errors.internal-server-error');
          }

          console.error(`Error correlation ID: ${errorResponse.error}`);
        }

        return throwError(() => errorResponse);
      })
    );
  }

  private isServerError(errorResponse: HttpErrorResponse): boolean {
    return (
      errorResponse.status >= HttpStatusCode.InternalServerError && errorResponse.status <= HttpStatusCode.NetworkAuthenticationRequired
    );
  }
}
