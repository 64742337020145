import { Action, Actions, ofActionDispatched, Selector, State, StateContext } from '@ngxs/store';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize, takeUntil, tap } from 'rxjs/operators';
import { OrganizationsService } from '@pages/organization-mapping/services/organizations.service';
import {
  CancelLoadingSubOrganizations,
  LoadNextSubOrganizations,
  LoadSubOrganizations,
} from '@pages/organization-mapping/store/actions/sub-organizations.actions';
import { append, patch } from '@ngxs/store/operators';
import { ISubOrganization } from '@pages/organization-mapping/models/organizations/sub-organization.interface';
import { ISubOrganizationsResponse } from '@pages/organization-mapping/models/organizations/sub-organizations-response.interface';

interface ISubOrganizationsStateModel {
  totalCount?: number;
  subOrganizations?: ISubOrganization[];
  loading: boolean;
}

@State<ISubOrganizationsStateModel>({
  name: 'organizationMappingSubOrganizations',
  defaults: { loading: false },
})
@Injectable()
export class SubOrganizationsState {
  private readonly organizationsService = inject(OrganizationsService);
  private readonly actions$ = inject(Actions);

  @Selector()
  static subOrganizations(state: ISubOrganizationsStateModel): ISubOrganization[] | undefined {
    return state.subOrganizations;
  }

  @Selector()
  static totalCount(state: ISubOrganizationsStateModel): number | undefined {
    return state?.totalCount;
  }

  @Selector()
  static loading(state: ISubOrganizationsStateModel): boolean {
    return state.loading;
  }

  @Action(LoadSubOrganizations, { cancelUncompleted: true })
  loadSubOrganizations(
    context: StateContext<ISubOrganizationsStateModel>,
    { subOrganizationsRequest }: LoadSubOrganizations
  ): Observable<ISubOrganizationsResponse | undefined> {
    context.patchState({ loading: true });

    return this.organizationsService.getSubOrganizations(subOrganizationsRequest).pipe(
      tap(({ totalCount, items }) => context.patchState({ totalCount, subOrganizations: items })),
      takeUntil(this.actions$.pipe(ofActionDispatched(CancelLoadingSubOrganizations))),
      finalize(() => context.patchState({ loading: false }))
    );
  }

  @Action(LoadNextSubOrganizations, { cancelUncompleted: true })
  loadNextSubOrganizations(
    context: StateContext<ISubOrganizationsStateModel>,
    { subOrganizationsRequest }: LoadNextSubOrganizations
  ): Observable<ISubOrganizationsResponse | undefined> {
    context.patchState({ loading: true });

    return this.organizationsService.getSubOrganizations(subOrganizationsRequest).pipe(
      tap(({ totalCount, items }) =>
        context.setState(
          patch({
            totalCount,
            subOrganizations: append(items),
          })
        )
      ),
      takeUntil(this.actions$.pipe(ofActionDispatched(CancelLoadingSubOrganizations))),
      finalize(() => context.patchState({ loading: false }))
    );
  }
}
