import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { IOrganizationsResponse } from '@pages/organization-mapping/models/organizations/organizations-response.interface';
import { IOrganizationsRequest } from '@pages/organization-mapping/models/organizations/organizations-request.interface';
import { ISubOrganizationsRequest } from '@pages/organization-mapping/models/organizations/sub-organizations-request.interface';
import { ISubOrganizationsResponse } from '@pages/organization-mapping/models/organizations/sub-organizations-response.interface';

@Injectable({
  providedIn: 'root',
})
export class OrganizationsService {
  private readonly httpClient = inject(HttpClient);

  getOrganizations(request: IOrganizationsRequest): Observable<IOrganizationsResponse> {
    const url = 'api/v1/organization-mappings/organizations/search';

    return this.httpClient.post<IOrganizationsResponse>(environment.portalApiUrl + url, request);
  }

  getSubOrganizations(request: ISubOrganizationsRequest): Observable<ISubOrganizationsResponse> {
    const url = 'api/v1/organization-mappings/sub-organizations/search';

    return this.httpClient.post<ISubOrganizationsResponse>(environment.portalApiUrl + url, request);
  }
}
