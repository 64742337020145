<nav class="navigation__container" [attr.data-test-id]="TestIds.leftNavigationContainer">
  <itc-navigation-link
    [testId]="TestIds.leftNavigationMenuItemId(home.id)"
    [routerLink]="home.routerLink"
    [iconSrc]="getIconPath(home.iconName)"
    [linkName]="'navigation.items.home' | translate"
    [isTooltipEnabled]="isCollapsed"
  />
  <itc-navigation-tagged-link
    *itcPermission="executiveReports.permission"
    [routerLink]="executiveReports.routerLink"
    [iconSrc]="getIconPath(executiveReports.iconName)"
    [linkName]="'navigation.items.executive-reports' | translate"
    [isTooltipEnabled]="isCollapsed"
  />
  <itc-navigation-external-link
    *itcPermission="kaseyaStore.permission"
    [menuItem]="kaseyaStore"
    [testId]="TestIds.leftNavigationMenuItemId(kaseyaStore.id)"
    [isTooltipEnabled]="isCollapsed"
  />
  <ng-container *itcFeature="Feature.OrganizationMappings">
    <itc-navigation-tagged-link
      *itcPermission="organizationMapping.permission"
      [routerLink]="organizationMapping.routerLink"
      [iconSrc]="getIconPath(organizationMapping.iconName)"
      [linkName]="'navigation.items.organization-mapping' | translate"
      [isTooltipEnabled]="isCollapsed"
    />
  </ng-container>
  <ng-container *itcFeature="Feature.Kaseya365SetupGuide">
    <ng-container *itcPermission="kaseya365SetupGuide.permission">
      <itc-navigation-tagged-link
        *itcK365SetupGuidesAccess
        [routerLink]="kaseya365SetupGuide.routerLink"
        [iconSrc]="getIconPath(kaseya365SetupGuide.iconName)"
        [linkName]="'navigation.items.kaseya-365-setup-guide' | translate"
        [isTooltipEnabled]="isCollapsed"
      />
    </ng-container>
  </ng-container>

  <itc-navigation-link
    *itcPermission="subscriptions.permission"
    [routerLink]="subscriptions.routerLink"
    [iconSrc]="getIconPath(subscriptions.iconName)"
    [linkName]="'navigation.items.subscriptions' | translate"
    [isTooltipEnabled]="isCollapsed"
  />
  <itc-navigation-external-link
    *itcPermission="viewAndPayInvoices.permission"
    [menuItem]="viewAndPayInvoices"
    [testId]="TestIds.leftNavigationMenuItemId(viewAndPayInvoices.id)"
    [isTooltipEnabled]="isCollapsed"
  />
  <ng-container *itcFeature="Feature.PaymentManagement">
    <itc-navigation-tagged-link
      *itcPermission="paymentManagement.permission"
      [routerLink]="paymentManagement.routerLink"
      [iconSrc]="getIconPath(paymentManagement.iconName)"
      [linkName]="'navigation.items.payment-management' | translate"
      [isTooltipEnabled]="isCollapsed"
    />
  </ng-container>
  <itc-navigation-external-link
    *itcPermission="paymentMethods.permission"
    [menuItem]="paymentMethods"
    [testId]="TestIds.leftNavigationMenuItemId(paymentMethods.id)"
    [isTooltipEnabled]="isCollapsed"
  />
  @for (internalMenuItem of internalMenuItems; track internalMenuItem.id) {
    <ng-container *itcFeature="internalMenuItem.feature">
      <ng-container *itcCountryAccess="internalMenuItem.accessibleCountries">
        <itc-navigation-link
          *itcPermission="internalMenuItem.permission"
          [testId]="TestIds.leftNavigationMenuItemId(internalMenuItem.id)"
          [routerLink]="internalMenuItem.routerLink"
          [iconSrc]="getIconPath(internalMenuItem.iconName)"
          [linkName]="'navigation.items.' + internalMenuItem.id | translate"
          [isTooltipEnabled]="isCollapsed"
        />
      </ng-container>
    </ng-container>
  }
  <itc-navigation-link
    *itcPermission="professionalServices.permission"
    [routerLink]="professionalServices.routerLink"
    [iconSrc]="getIconPath(professionalServices.iconName)"
    [linkName]="'navigation.items.professional-services' | translate"
    [isTooltipEnabled]="isCollapsed"
  />
  <itc-navigation-external-link
    [menuItem]="absorb"
    [testId]="TestIds.leftNavigationMenuItemId(absorb.id)"
    [isTooltipEnabled]="isCollapsed"
  />
  <itc-navigation-external-link
    *itcPermission="Permission.KaseyaCommunity"
    [menuItem]="kaseyaCommunity"
    [testId]="TestIds.leftNavigationMenuItemId(kaseyaCommunity.id)"
    [isTooltipEnabled]="isCollapsed"
  />
  @if ((isPartnerProgramActive$ | async) === true) {
    <itc-navigation-link
      *itcPermission="Permission.PartnerProgram"
      [testId]="TestIds.leftNavigationMenuItemId(partnerProgram.id)"
      [routerLink]="partnerProgram.routerLink"
      [iconSrc]="getIconPath(partnerProgram.iconName)"
      [linkName]="'navigation.items.partner-program' | translate"
      [isTooltipEnabled]="isCollapsed"
    />
  }
  <itc-navigation-external-link [menuItem]="kaseyaEvents" [isTooltipEnabled]="isCollapsed" />
  <itc-navigation-external-link
    *itcPermission="Permission.KaseyaSwag"
    [menuItem]="kaseyaSwag"
    [testId]="TestIds.leftNavigationMenuItemId(kaseyaSwag.id)"
    [isTooltipEnabled]="isCollapsed"
  />
</nav>
