import { Action, Selector, State, StateContext } from '@ngxs/store';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { IPotentialMapping } from '@pages/organization-mapping/models/potential-mappings/potential-mapping.interface';
import { LoadPotentialMappings } from '@pages/organization-mapping/store/actions/potential-mappings.actions';
import { PotentialMappingsService } from '@pages/organization-mapping/services/potential-mappings.service';

interface IPotentialMappingsStateModel {
  potentialMappings?: IPotentialMapping[];
  loading: boolean;
}

@State<IPotentialMappingsStateModel>({
  name: 'organizationMappingPotentialMappings',
  defaults: { loading: false },
})
@Injectable()
export class PotentialMappingsState {
  private readonly potentialMappingsService = inject(PotentialMappingsService);

  @Selector()
  static potentialMappings(state: IPotentialMappingsStateModel): IPotentialMapping[] | undefined {
    return state.potentialMappings;
  }

  @Selector()
  static loading(state: IPotentialMappingsStateModel): boolean {
    return state.loading;
  }

  @Action(LoadPotentialMappings, { cancelUncompleted: true })
  loadPotentialMappings(
    context: StateContext<IPotentialMappingsStateModel>,
    { potentialMappingsRequest }: LoadPotentialMappings
  ): Observable<IPotentialMapping[] | undefined> {
    context.patchState({ loading: true });

    return this.potentialMappingsService.getPotentialMappings(potentialMappingsRequest).pipe(
      tap(potentialMappings => context.patchState({ potentialMappings })),
      finalize(() => context.patchState({ loading: false }))
    );
  }
}
