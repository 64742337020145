import { IOrganizationMapping } from '@pages/organization-mapping/models/organization-mapping.interface';

export class LoadOrganizationMapping {
  static readonly type = '[Organization Mapping] Load Organization Mapping';
  constructor(public organizationMappingId: string) {}
}

export class UpdateOrganizationMapping {
  static readonly type = '[Organization Mapping] Update Organization Mapping';
  constructor(
    public organizationMapping: IOrganizationMapping,
    public nextOrganizationMappingId?: string
  ) {}
}

export class UpdateOrganizationMappingCompleted {
  static readonly type = '[Organization Mapping] Update Organization Mapping Completed';
}

export class LoadNextOrganizationMapping {
  static readonly type = '[Organization Mapping] Load Next Organization Mapping';
}

export class LoadPreviousOrganizationMapping {
  static readonly type = '[Organization Mapping] Load Previous Organization Mapping';
}

export class CancelOrganizationMappingRequest {
  static readonly type = '[Organization Mapping] Cancel Organization Mapping Request';
}
