import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { IPotentialMappingsRequest } from '@pages/organization-mapping/models/potential-mappings/potential-mappings-request.interface';
import { IPotentialMapping } from '@pages/organization-mapping/models/potential-mappings/potential-mapping.interface';

@Injectable({
  providedIn: 'root',
})
export class PotentialMappingsService {
  private readonly httpClient = inject(HttpClient);

  getPotentialMappings(request: IPotentialMappingsRequest): Observable<IPotentialMapping[]> {
    const url = 'api/v1/organization-mappings/potential-mappings/search';

    return this.httpClient.post<IPotentialMapping[]>(environment.portalApiUrl + url, request);
  }
}
