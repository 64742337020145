import { inject } from '@angular/core';
import { CanMatchFn, Route, Router, UrlTree } from '@angular/router';
import { UserInfoFacade } from '@core/user-info/facades/user-info.facade';
import { filterIsNotUndefined } from '@shared/rxjs-operators/filter-is-not-undefined';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { toObservable } from '@angular/core/rxjs-interop';

export const canMatchCountry: CanMatchFn = (route: Route): Observable<boolean | UrlTree> => {
  const router = inject(Router);
  const userInfoFacade = inject(UserInfoFacade);
  const accessibleCountries = route.data?.accessibleCountries;

  return toObservable(userInfoFacade.userInfo).pipe(
    filterIsNotUndefined(),
    map(() => {
      if (accessibleCountries && !userInfoFacade.isUserFromCountries(accessibleCountries)) {
        return router.createUrlTree(['access-denied']);
      }

      return true;
    })
  );
};
