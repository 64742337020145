import { ISubOrganizationsRequest } from '@pages/organization-mapping/models/organizations/sub-organizations-request.interface';

export class LoadSubOrganizations {
  static readonly type = '[Organization Mapping Sub Organizations] Load Sub Organizations';
  constructor(public subOrganizationsRequest: ISubOrganizationsRequest) {}
}

export class LoadNextSubOrganizations {
  static readonly type = '[Organization Mapping Sub Organizations] Load Next Sub Organizations';
  constructor(public subOrganizationsRequest: ISubOrganizationsRequest) {}
}

export class CancelLoadingSubOrganizations {
  static readonly type = '[Organization Mapping Sub Organizations] Cancel Loading Sub Organizations';
}
