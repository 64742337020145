import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationError, Router } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { IAppRouteData } from '@core/route-data/models/route-data.interface';
import { isNotUndefined } from '@shared/utils/is-not-undefined';

@Injectable({
  providedIn: 'root',
})
export class RouteDataService {
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.handleChunkError();
  }

  getDataOfTheLastRoute(): Observable<IAppRouteData> {
    return this.getLastActivatedRoute().pipe(mergeMap(route => route.data));
  }

  getDataWithTheDeepestProperty(property: keyof IAppRouteData): Observable<IAppRouteData | undefined> {
    return this.getLastActivatedRouteWithProperty(property).pipe(mergeMap(route => route?.data ?? of(undefined)));
  }

  afterNavigationEnd(): Observable<void> {
    return this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => void 0)
    );
  }

  private getLastActivatedRoute(): Observable<ActivatedRoute> {
    return this.afterNavigationEnd().pipe(map(() => this.calculateLastActivatedRoute()));
  }

  private getLastActivatedRouteWithProperty(property: keyof IAppRouteData): Observable<ActivatedRoute | undefined> {
    return this.afterNavigationEnd().pipe(map(() => this.calculateLastActivatedRouteWithProperty(property)));
  }

  private getAllRoutesInTree(): ActivatedRoute[] {
    const routes = [this.activatedRoute];
    let lastRoute = this.activatedRoute;
    while (lastRoute.firstChild) {
      routes.push(lastRoute.firstChild);
      lastRoute = lastRoute.firstChild;
    }
    return [...routes];
  }

  private calculateLastActivatedRoute(): ActivatedRoute {
    const indexOfLastItem = this.getAllRoutesInTree().length - 1;
    return this.getAllRoutesInTree()[indexOfLastItem];
  }

  private calculateLastActivatedRouteWithProperty(property: keyof IAppRouteData): ActivatedRoute | undefined {
    return this.getAllRoutesInTree()
      .reverse()
      .find(route => isNotUndefined(route.snapshot?.data[property]));
  }

  private handleChunkError(): void {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationError),
        map(event => event as NavigationError)
      )
      .subscribe(({ error }) => {
        if (error instanceof Error && (error.name == 'ChunkLoadError' || error.name == 'TypeError')) {
          window.location.reload();
        }
      });
  }
}
