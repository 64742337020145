import { inject } from '@angular/core';
import { CanMatchFn, Router, UrlTree } from '@angular/router';
import { map, Observable, of } from 'rxjs';
import { PermissionsFacade } from '@core/permissions/facades/permissions.facade';
import { FirstTimeOnboardingCompletedFacade } from '@layout/first-time-onboarding/facades/first-time-onboarding-completed.facade';
import { filterIsNotUndefined } from '@shared/rxjs-operators/filter-is-not-undefined';

export const canMatchFirstTimeOnboardingCompleted: CanMatchFn = (): Observable<boolean | UrlTree> => {
  const firstTimeOnboardingCompletedFacade = inject(FirstTimeOnboardingCompletedFacade);
  const permissionsFacade = inject(PermissionsFacade);
  const router = inject(Router);

  if (!permissionsFacade.hasAccessToOnboardingFirstMasterUser) {
    return of(true);
  }

  firstTimeOnboardingCompletedFacade.loadIsOnboardingCompletedIfNotLoaded();

  return firstTimeOnboardingCompletedFacade.isOnboardingCompleted$.pipe(
    filterIsNotUndefined(),
    map(isCompleted => {
      return isCompleted || router.createUrlTree(['onboarding']);
    })
  );
};

export const canMatchFirstTimeOnboardingAccess: CanMatchFn = (): Observable<boolean | UrlTree> => {
  const firstTimeOnboardingCompletedFacade = inject(FirstTimeOnboardingCompletedFacade);
  const router = inject(Router);

  firstTimeOnboardingCompletedFacade.loadIsOnboardingCompletedIfNotLoaded();

  return firstTimeOnboardingCompletedFacade.isOnboardingCompleted$.pipe(
    filterIsNotUndefined(),
    map(isCompleted => {
      return !isCompleted || router.createUrlTree(['']);
    })
  );
};
