import { Action, Actions, ofActionDispatched, Selector, State, StateContext } from '@ngxs/store';
import { inject, Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, takeUntil, tap } from 'rxjs/operators';
import { OrganizationsService } from '@pages/organization-mapping/services/organizations.service';
import { IOrganizationsResponse } from '@pages/organization-mapping/models/organizations/organizations-response.interface';
import {
  LoadOrganizations,
  LoadNextOrganizations,
  CancelLoadingOrganizations,
} from '@pages/organization-mapping/store/actions/organizations.actions';
import { IOrganization } from '@pages/organization-mapping/models/organizations/organization.interface';
import { append, patch } from '@ngxs/store/operators';
import { HttpErrorResponse } from '@angular/common/http';

interface IOrganizationsStateModel {
  totalCount?: number;
  organizations?: IOrganization[];
  loading: boolean;
  error: boolean;
}

@State<IOrganizationsStateModel>({
  name: 'organizationMappingOrganizations',
  defaults: { loading: false, error: false },
})
@Injectable()
export class OrganizationsState {
  private readonly organizationsService = inject(OrganizationsService);
  private readonly actions$ = inject(Actions);

  @Selector()
  static organizations(state: IOrganizationsStateModel): IOrganization[] | undefined {
    return state.organizations;
  }

  @Selector()
  static totalCount(state: IOrganizationsStateModel): number | undefined {
    return state?.totalCount;
  }

  @Selector()
  static loading(state: IOrganizationsStateModel): boolean {
    return state.loading;
  }

  @Selector()
  static error(state: IOrganizationsStateModel): boolean {
    return state.error;
  }

  @Action(LoadOrganizations, { cancelUncompleted: true })
  loadOrganizations(
    context: StateContext<IOrganizationsStateModel>,
    { organizationsRequest }: LoadOrganizations
  ): Observable<IOrganizationsResponse | undefined> {
    context.patchState({ loading: true, error: false });

    return this.organizationsService.getOrganizations(organizationsRequest).pipe(
      tap(({ totalCount, items }) => context.patchState({ totalCount, organizations: items })),
      catchError((errorResponse: HttpErrorResponse) => {
        context.patchState({ error: true });
        return throwError(() => errorResponse);
      }),
      takeUntil(this.actions$.pipe(ofActionDispatched(CancelLoadingOrganizations))),
      finalize(() => context.patchState({ loading: false }))
    );
  }

  @Action(LoadNextOrganizations, { cancelUncompleted: true })
  loadNextOrganizations(
    context: StateContext<IOrganizationsStateModel>,
    { organizationsRequest }: LoadNextOrganizations
  ): Observable<IOrganizationsResponse | undefined> {
    context.patchState({ loading: true, error: false });

    return this.organizationsService.getOrganizations(organizationsRequest).pipe(
      tap(({ totalCount, items }) =>
        context.setState(
          patch({
            totalCount,
            organizations: append(items),
          })
        )
      ),
      catchError((errorResponse: HttpErrorResponse) => {
        context.patchState({ error: true });
        return throwError(() => errorResponse);
      }),
      takeUntil(this.actions$.pipe(ofActionDispatched(CancelLoadingOrganizations))),
      finalize(() => context.patchState({ loading: false }))
    );
  }
}
