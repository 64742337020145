import { ConnectedModulesState } from '@pages/organization-mapping/store/state/connected-modules.state';
import { OrganizationMappingSynchronizationState } from '@pages/organization-mapping/store/state/organization-mapping-synchronization.state';
import { OrganizationMappingsState } from '@pages/organization-mapping/store/state/organization-mappings.state';
import { OrganizationsState } from '@pages/organization-mapping/store/state/organizations.state';
import { PotentialMappingsState } from '@pages/organization-mapping/store/state/potential-mappings.state';
import { SubOrganizationsState } from '@pages/organization-mapping/store/state/sub-organizations.state';
import { OrganizationMappingState } from '@pages/organization-mapping/store/state/organization-mapping.state';

export const organizationMappingState = [
  ConnectedModulesState,
  OrganizationMappingSynchronizationState,
  OrganizationMappingsState,
  OrganizationsState,
  SubOrganizationsState,
  PotentialMappingsState,
  OrganizationMappingState,
];
