import { IOrganizationMapping } from '@pages/organization-mapping/models/organization-mapping.interface';
import { IOrganizationMappingRequest } from '@pages/organization-mapping/models/organization-mapping-request.interface';

export const mapOrganizationMappingToRequest = ({
  organizationMappingId,
  organizationName,
  customId,
  moduleMappings,
}: IOrganizationMapping): IOrganizationMappingRequest => ({
  organizationMappingId,
  organizationName,
  customId,
  moduleMappings: moduleMappings.map(({ moduleId, instanceMappings }) => ({
    moduleId,
    instanceMappings: instanceMappings.map(({ sourceInstanceId, status, mappedOrganization }) => ({
      sourceInstanceId,
      status,
      mappedSourceOrganizationId: mappedOrganization?.sourceOrganizationId || null,
      mappedSourceSubOrganizationId: mappedOrganization?.sourceSubOrganizationId || null,
    })),
  })),
});
