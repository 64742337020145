import { NgModule } from '@angular/core';
import { AppComponent } from '@app/app.component';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from '@app/app-routing.module';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RefreshTokenInterceptor } from '@core/authorization/interceptors/refresh-token.interceptor';
import { TokenInterceptor } from '@core/authorization/interceptors/token.interceptor';
import { LogErrorsInterceptor } from '@core/logger/interceptors/log-errors.interceptor';
import { AuthorizationModule } from '@core/authorization/authorization.module';
import { ErrorsInterceptor } from '@core/errors/interceptors/errors.interceptor';
import { NgSelectModule } from '@ng-select/ng-select';
import { PermissionsModule } from '@core/permissions/permissions.module';
import { TranslationModule } from '@core/translation/translation.module';
import { LocationsModule } from '@core/locations/locations.module';
import { provideEnvironmentNgxMask } from 'ngx-mask';
import { SidePanelModule } from '@design/side-panel/side-panel.module';
import { MobileStubComponent } from '@layout/mobile-stub/components/mobile-stub/mobile-stub.component';
import { AuthViewComponent } from '@layout/auth-view/components/auth-view.component';
import { MainViewComponent } from '@layout/main-view/components/main-view.component';
import { SessionTimeoutComponent } from '@layout/session-timeout/components/session-timeout/session-timeout.component';
import { RequestForbiddenInterceptor } from '@core/errors/interceptors/request-forbidden.interceptor';
import { NgxsModule } from '@ngxs/store';
import { kaseya365UpgradeState as kaseya365State } from '@layout/kaseya-365/store';
import { launcherState } from '@layout/launcher/store';
import { notificationsState } from '@layout/notifications/store';
import { moduleConfigurationsStates } from '@layout/module-management/store';
import { featuresState } from '@core/features/store';
import { organizationManagersState } from '@layout/organization-managers/store';
import { whatsNewState } from '@layout/whats-new/store';
import { environment } from '@env/environment';
import { withNgxsResetPlugin } from 'ngxs-reset-plugin';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { partnerProgramState } from '@layout/partner-program/store';
import { onboardingVideoDialogState } from '@layout/onboarding-video-dialog/store';
import { applicationVersionState } from '@core/application-version/store';
import TenantsState from '@core/sso/store/index';
import { firstTimeOnboardingCompletedStates } from '@layout/first-time-onboarding/store';
import { k365SubscriptionsState } from '@layout/k365-subscriptions/store';
import { partnerProgramActiveState } from '@layout/partner-program-active/store';
import { userInfoState } from '@core/user-info/store';
import { provideDialogEnvironment } from '@design/dialog/models/dialog.environment';
import { provideUserInfoEnvironment } from '@core/user-info/models/user-info.environment';
import { RetryWhenOnlineInterceptor } from '@core/errors/interceptors/retry-when-online.interceptor';

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    NgSelectModule,
    TranslationModule,
    ToastrModule.forRoot({
      preventDuplicates: true,
      resetTimeoutOnDuplicate: true,
      tapToDismiss: false,
    }),
    NgxsModule.forRoot(
      [
        ...partnerProgramState,
        ...kaseya365State,
        ...launcherState,
        ...notificationsState,
        ...moduleConfigurationsStates,
        ...featuresState,
        ...organizationManagersState,
        ...whatsNewState,
        ...onboardingVideoDialogState,
        ...applicationVersionState,
        ...TenantsState,
        ...firstTimeOnboardingCompletedStates,
        ...k365SubscriptionsState,
        ...partnerProgramActiveState,
        ...userInfoState,
      ],
      {
        developmentMode: !environment.production,
      }
    ),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    AppRoutingModule,
    MainViewComponent,
    AuthViewComponent,
    SessionTimeoutComponent,
    AuthorizationModule,
    PermissionsModule,
    ReactiveFormsModule,
    LocationsModule,
    SidePanelModule,
    MobileStubComponent,
  ],
  providers: [
    withNgxsResetPlugin(),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RefreshTokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestForbiddenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LogErrorsInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorsInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RetryWhenOnlineInterceptor,
      multi: true,
    },
    provideEnvironmentNgxMask(),
    provideHttpClient(withInterceptorsFromDi()),
    provideDialogEnvironment(),
    provideUserInfoEnvironment(),
  ],
})
export class AppModule {}
