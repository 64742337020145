import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { IOrganizationMappings } from '@pages/organization-mapping/models/organization-mappings.interface';
import { IOrganizationMappingsRequest } from '@pages/organization-mapping/models/organization-mappings-request.interface';
import { IOrganizationMapping } from '@pages/organization-mapping/models/organization-mapping.interface';
import { IOrganizationMappingRequest } from '@pages/organization-mapping/models/organization-mapping-request.interface';

@Injectable({
  providedIn: 'root',
})
export class OrganizationMappingsService {
  private readonly httpClient = inject(HttpClient);

  getOrganizationMappings(organizationMappingRequest: IOrganizationMappingsRequest): Observable<IOrganizationMappings> {
    const url = 'api/v1/organization-mappings/search';

    return this.httpClient.post<IOrganizationMappings>(environment.portalApiUrl + url, organizationMappingRequest);
  }

  getOrganizationMapping(organizationMappingId: string): Observable<IOrganizationMapping> {
    const url = `api/v1/organization-mappings/${organizationMappingId}`;

    return this.httpClient.get<IOrganizationMapping>(environment.portalApiUrl + url);
  }

  updateOrganizationMapping(organizationMappingId: string, organizationMappingRequest: IOrganizationMappingRequest): Observable<void> {
    const url = `api/v1/organization-mappings/${organizationMappingId}`;

    return this.httpClient.put<void>(environment.portalApiUrl + url, organizationMappingRequest);
  }
}
