import { NgModule } from '@angular/core';
import { CountryAccessDirective } from '@core/locations/directives/country-access.directive';
import { NgxsModule } from '@ngxs/store';
import { locationsState } from '@core/locations/store';

@NgModule({
  declarations: [CountryAccessDirective],
  exports: [CountryAccessDirective],
  imports: [NgxsModule.forFeature([...locationsState])],
})
export class LocationsModule {}
