import { Action, Actions, ofActionDispatched, Selector, State, StateContext } from '@ngxs/store';
import { inject, Injectable } from '@angular/core';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError, finalize, takeUntil, tap } from 'rxjs/operators';
import { IOrganizationMapping } from '@pages/organization-mapping/models/organization-mapping.interface';
import { OrganizationMappingsService } from '@pages/organization-mapping/services/organization-mappings.service';
import {
  CancelOrganizationMappingRequest,
  LoadOrganizationMapping,
  UpdateOrganizationMapping,
  UpdateOrganizationMappingCompleted,
} from '@pages/organization-mapping/store/actions/organization-mapping.actions';
import { mapOrganizationMappingToRequest } from '@pages/organization-mapping/mappers/organization-mapping-to-request.mapper';
import { HttpErrorResponse } from '@angular/common/http';

interface IOrganizationMappingStateModel {
  organizationMapping?: IOrganizationMapping;
  loading: boolean;
  error: boolean;
}

@State<IOrganizationMappingStateModel>({
  name: 'organizationMapping',
  defaults: {
    loading: false,
    error: false,
  },
})
@Injectable()
export class OrganizationMappingState {
  private readonly organizationMappingsService = inject(OrganizationMappingsService);
  private readonly actions$ = inject(Actions);

  @Selector()
  static organizationMapping(state: IOrganizationMappingStateModel): IOrganizationMapping | undefined {
    return state.organizationMapping;
  }

  @Selector()
  static loading(state: IOrganizationMappingStateModel): boolean {
    return state.loading;
  }

  @Selector()
  static error(state: IOrganizationMappingStateModel): boolean {
    return state.error;
  }

  @Action(LoadOrganizationMapping)
  loadOrganizationMapping(
    context: StateContext<IOrganizationMappingStateModel>,
    { organizationMappingId }: LoadOrganizationMapping
  ): Observable<IOrganizationMapping> {
    context.patchState({ loading: true, error: false });

    return this.organizationMappingsService.getOrganizationMapping(organizationMappingId).pipe(
      tap(organizationMapping => context.patchState({ organizationMapping })),
      catchError(() => {
        context.patchState({ error: true });
        return EMPTY;
      }),
      finalize(() => context.patchState({ loading: false })),
      takeUntil(this.actions$.pipe(ofActionDispatched(CancelOrganizationMappingRequest)))
    );
  }

  @Action(UpdateOrganizationMapping)
  updateOrganizationMapping(
    context: StateContext<IOrganizationMappingStateModel>,
    { organizationMapping, nextOrganizationMappingId }: UpdateOrganizationMapping
  ): Observable<void> {
    context.patchState({ loading: true, error: false });

    return this.organizationMappingsService
      .updateOrganizationMapping(organizationMapping.organizationMappingId, mapOrganizationMappingToRequest(organizationMapping))
      .pipe(
        tap(() => {
          if (nextOrganizationMappingId) {
            context.dispatch(new LoadOrganizationMapping(nextOrganizationMappingId));
            return;
          }

          context.patchState({ loading: false });
        }),
        tap(() => context.dispatch(new UpdateOrganizationMappingCompleted())),
        catchError((errorResponse: HttpErrorResponse) => {
          context.patchState({ loading: false, error: true });
          return throwError(() => errorResponse);
        }),
        takeUntil(this.actions$.pipe(ofActionDispatched(CancelOrganizationMappingRequest)))
      );
  }
}
